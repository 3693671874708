import i18next from 'i18next';
import locI18next from 'loc-I18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import it from '../src/locales/it/translation.json';
import fr from '../src/locales/fr/translation.json';
import en from '../src/locales/en/translation.json';
import PopperJs from 'popper.js';
import jquery from 'jquery';
import 'magnific-popup';

require('./styles/index.scss');

i18next
    .use(LanguageDetector)
    .init({
        lng: null,
        debug: true,
        resources: {
            en,
            fr,
            it,
        }
    }, function (err, t) {
        // init set content
        // updateContent();
    });

const localize = locI18next.init(i18next, {
    selectorAttr: 'data-i18n', // selector for translating elements
    // targetAttr: 'i18n-target',
    // optionsAttr: 'i18n-options',
    useOptionsAttr: true,
    parseDefaultValueFromContent: false
});

localize("[data-i18n]");

jquery(() => {
    const $ = jquery;
    const $btn = $('.menu-btn');

    $('.menu-layer ul li a.link').on("click", function () {
        $btn.prop('checked', false);
        event.preventDefault();
        var href = $(this).attr('href').replace('#', '')
        scrollToAnchor(href);
    });

    $('.lang').click(function () {
        $btn.prop('checked', false);
        const lang = $(this).data('language');
        i18next.changeLanguage(lang);
        localize("[data-i18n]");
    });

    var scrollToAnchor = function (id) {
        var elem = $("section[id='" + id + "']");
        if (typeof elem.offset() === "undefined") {
            elem = $("#" + id);
        }
        if (typeof elem.offset() !== "undefined") {
            $('html, body').animate({
                scrollTop: elem.offset().top
            }, 600);
        }
    };

    $('.view-project').magnificPopup({
		type: 'inline',
		fixedContentPos: false,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
        preloader: false,
        // closeMarkup: '<button title="%title%" type="button" class="mfp-close">&#215;</button>',
		midClick: true,
		mainClass: 'my-mfp-zoom-in'
	});

});
